import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";

export function Contact(){
  return(
    <Container id="contact">
      <header>
        <h2>Contact</h2>
      </header>
      <div className="contacts">
        <div>
          <img src={emailIcon} alt="Email" />
          <a href="mailto:konrad@kadzielawa.dev">konrad@kadzielawa.dev</a>
        </div>
      </div>
    </Container>
  )
}
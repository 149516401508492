import { Container } from "./styles";
import wordpressIcon from "../../assets/wordpress-icon.svg";
import lessIcon from "../../assets/less-icon.svg";
import htmlIcon from "../../assets/html-icon.svg";
import cssIcon from "../../assets/css-icon.svg";
import sassIcon from "../../assets/sass-icon.svg";
import jsIcon from "../../assets/js-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
import typescriptIcon from "../../assets/typescript-icon.svg";
import vueIcon from "../../assets/vue-icon.svg";
import boostrapIcon from "../../assets/bootstrap-icon.svg";
import nuxtIcon from "../../assets/nuxt-icon.svg";
import graphqlIcon from "../../assets/graphql-icon.svg";
import psIcon from "../../assets/ps-icon.svg";
import svelteIcon from "../../assets/svelte-icon.svg";
import storybookIcon from "../../assets/storybook-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";


export function experience() {
  const currentDate = new Date().getFullYear();
  const startDate = 2016;

  return currentDate - startDate;
}

export function About() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>
            About me
          </h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
            My passion is creation and I'm happy to be even better the next day.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
            I have more than  { experience() }  years of experience in the daily challenges of programming in javascript and vuejs. I take into account the challenges, because I know what benefits they can bring, e.g. in the form of development. Currently I am involved in a big project of which I can be pro ud of.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
            I am a graduate of the Pedagogical University of Krakow with a Master's degree in Computer Science. I hold the Magento Frontend Developer certificate and currently work at STRIX POLAND Ltd.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
            My motto, don't count how much you managed to do well and count what you didn't do.
          </p>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Here are my main skills:</h3>
        </ScrollAnimation>

        <div className="hard-skills">
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.10 * 1000}>
              <img src={nuxtIcon} alt="nuxtjs" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.14 * 1000}>
              <img src={typescriptIcon} alt="Typescript" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.18 * 1000}>
              <img src={vueIcon} alt="Vue" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.22 * 1000}>
              <img src={jsIcon} alt="JavaScript" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.26 * 1000}>
              <img src={nodeIcon} alt="Node" />
            </ScrollAnimation>
            </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.30 * 1000}>
              <img src={graphqlIcon} alt="GraphQL" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.34 * 1000}>
              <img src={storybookIcon} alt="storybook" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.38 * 1000}>
              <img src={htmlIcon} alt="Html" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.42 * 1000}>
              <img src={cssIcon} alt="Css" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.46 * 1000}>
              <img src={sassIcon} alt="Sass" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.50 * 1000}>
              <img src={lessIcon} alt="Less" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.54 * 1000}>
              <img src={reactIcon} alt="React" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.58 * 1000}>
              <img src={svelteIcon} alt="Svelte" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.62 * 1000}>
              <img src={boostrapIcon} alt="bootstrap" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.66 * 1000}>
              <img src={psIcon} alt="adobe photoshop" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.70 * 1000}>
              <img src={wordpressIcon} alt="Wordpress" />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </Container>
  )
}

import { Container } from './styles'

import reactIcon from '../../assets/react-icon.svg'
import linkedin from '../../assets/linkedin-icon.svg'
import githubIcon from '../../assets/github-icon.svg'
//import devtoIcon from '../../assets/devto-icon.svg'
import devtoIcon from '../../assets/devto-icon.png'


export function Footer() {
  return (
    <Container className="footer">
      <a href="/" className="copy">
        <span>© {  new Date().getFullYear() }</span>
      </a>
      <div>
        <p>
          This Website was made with <img src={reactIcon} alt="React" />
          {/* <span>❤️</span> */}
        </p>
      </div>
      <div className="social-media">
        <a
          href="https://dev.to/kkadzielawa"
          target="_blank"
          rel="noreferrer"
        >
          <img src={devtoIcon} alt="DevTo" />
        </a>
        <a
          href="https://www.linkedin.com/in/kkadzielawa"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="Linkedin" />
        </a>
        <a
          href="https://github.com/gyzamaz"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a>
      </div>
    </Container>
  )
}

import { Container } from "./styles";
import externalLink from "../../assets/external-link.svg"
import ScrollAnimation from "react-animate-on-scroll";
import castoramaLogo from "../../assets/castorama-logo.svg";

export function Portfolio() {
  return (
    <Container id="portfolio">
      <h2>Work Experience</h2>
      <div className="projects">
      <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <img src={castoramaLogo}  style={{ maxWidth: "192px"}} />
              <div className="project__links">
                <a href="https://www.castorama.pl/" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="project__body">
              <div className="project__heading">
                <h3>Castorama</h3>
                <h4>I joined the project in 2016.</h4>
              </div>
              <p className="">
               Castorama is the largest chain of DIY shops in Poland and a leader in the construction and renovation sector. Its history on our market started in 1997 when the first store was opened in Warsaw. Today, Castorama has over 91 brick and mortar shops in Poland, employs 12,500 people, and offers over 50,000 products. And on top of that, it has one of the most modern e-commerce in its sector! 
              </p>
            </div>
            <footer>
              <ul className="project__tech-list">
                <li>JavaScript</li>
                <li>Nuxt.js</li>
                <li>Cypress.io</li>
                <li>jestjs.io</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div>
    </Container>
  );
}